<template>
  <div class="footer" v-if="show">
    <img class="logo" :src="require('@/assets/img/logo.png')" />

    <div class="text">
      <p>
        <a
          style="color: #2e2e2e"
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >浙ICP备2024091037号</a
        >
      </p>
      <p>©2022-2025 浙江宇创世纪科技股份有限公司</p>
      <p>抵制不良游戏，拒绝盗版游戏。 注意自我保护，谨防受骗上当。</p>
      <p>适度游戏益脑，沉迷游戏伤身。 合理安排时间，享受健康生活。</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const show = ref(false)
const load = computed(() => store.state.load)
watch(load, (newV) => {
  if (newV == false) {
    setTimeout(() => {
      show.value = true
    }, 1000)
  } else {
    show.value = false
  }
})
</script>

<style scoped>
.footer {
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 28%;
  background: #fff;
  height: 200px;
  font-size: 14px;
  color: #2e2e2e;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.logo {
  width: 100px;
  margin-right: 50px;
}
.text {
  text-align: left;
  line-height: 20px;
}
@media screen and (max-width: 750px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 0;
  }
  .logo {
    width: 1rem;
    margin-bottom: 10px;
  }
  .text {
    font-size: 12px;
    text-align: center;
  }
}
</style>
